<template>
    <div>
        <!--begin::Row-->
        <!-- <div class="row">
        <div class="col-lg-12"> -->
        <div class="card card-custom card-stretch gutter-b">
            <!--begin::Header-->
            <div class="card-header border-0">
                <h3 class="card-title font-weight-bolder text-dark">Uji Petik Kementerian/Lembaga</h3>
                <div class="card-toolbar">

                </div>
            </div>
            <!--end::Header-->
            <!--begin::Body-->
            <div class="card-body pt-0">
                <!--begin::Item-->
                <div class="mb-12">
                    <!--begin::Content-->
                    <div>
                        <div style="overflow-x:auto;">
                            <table id="example" class="table table-striped table-bordered" style="width:100%">
                                <thead>
                                    <tr>
                                        <th style="width:5%">No</th>
                                        <th style="width:30%">Nama Kementerian/Lembaga</th>
                                        <th style="width:15%">Penilaian Uji Petik</th>
                                        <th style="width:10%">Nilai Uji Petik</th>
                                        <th style="width:10%">Catatan</th>
                                        <th style="width:20%">Rekomendasi <br> <small style="color:red">(Hanya salah satu
                                                tim penilai input rekomendasi)</small></th>
                                        <th style="width:10%">BA</th>
                                    </tr>

                                </thead>
                                <tbody>
                                    <tr v-for="row in totskor" :key="row.id">
                                        <td></td>
                                        <td>
                                            <div style="width:300px">{{ row.nama_peserta }}</div>
                                        </td>
                                        <td>
                                            <div style="width:150px" v-if="row.status === 'Unlock'">
                                                <b-button v-if="row.idpenilaian == null" variant="success"
                                                    v-b-modal.modal-input
                                                    @click="getdatakl(row.idpenilai, row.idpeserta)">Input Nilai</b-button>
                                                <b-button v-else variant="success" v-b-modal.modal-edit
                                                    @click="getdatakledit(row.idpenilaian)">Ubah Nilai</b-button>
                                            </div>
                                        </td>
                                        <td><b-button v-if="row.nilai_uji_petik != null" size="sm"
                                                style="background-color:#F9F9F9;" @click="nilai(row)" v-b-tooltip.hover
                                                title="Lihat data nilai"><i class="flaticon-eye"></i></b-button></td>
                                        <td>
                                            <button data-toggle="modal" data-target=".catatan"
                                                @click="getcatatan(row.idpeserta)" style="margin:5px"
                                                class="btn btn-primary">Lihat</button>
                                        </td>
                                        <td>
                                            <button @click="cekrekomendasi(row.idpenilai, row.idpeserta)"
                                                style="background-color: #030A8C; border-color: #030A8C; color:white"
                                                class="btn btn-primary">Input Rekomendasi</button><br>
                                        </td>
                                        <td>
                                            <button type="button" @click="cekba(row.idpeserta)"
                                                style="background-color: #004d00; border-color: #004d00"
                                                class="btn btn-success"><i class="fa fa-download" aria-hidden="true" />
                                                Unduh BA</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!--end::Content-->
                </div>
                <!--end::Item-->
            </div>
            <!--end: Card Body-->

            <!-- input catatan -->
            <b-modal v-model="show" id="modal-input" ref="modal" size="xl" modal-class="test-modal" title="Form Input Nilai"
                @show="resetModal" :no-close-on-backdrop="true" @ok="handleOk">
                <form ref="form" @submit.stop.prevent="inputnilai">
                    <div class="alert alert-primary d-flex align-items-center" role="alert">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                            class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img"
                            aria-label="Warning:">
                            <path
                                d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                        </svg>
                        <div>
                            <span style="padding-right:20px"> </span> Masukkan Penilaian Anda dengan rentang 1-100
                        </div>
                    </div>

                    <div style="overflow-x:auto;">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th class="tg-1wig" colspan="2">Kriteria Penilaian</th>
                                    <th class="tg-1wig" colspan="2">Indikator Penilaian</th>
                                    <th>Nilai</th>
                                    <th class="tg-0lax">Tolok Ukur</th>
                                    <th class="tg-0lax"><span
                                            style="font-weight:700;font-style:normal;text-decoration:none">Sangat
                                        </span><span style="font-weight:700">Bagus (85 sd 100)</span></th>
                                    <th class="tg-0lax"><span
                                            style="font-weight:700;font-style:normal;text-decoration:none">Bagus
                                        </span><span style="font-weight:700">(70 sd 84,9)</span></th>
                                    <th class="tg-0lax"><span
                                            style="font-weight:700;font-style:normal;text-decoration:none">Kurang</span><span
                                            style="font-weight:700"> Bagus (65 sd 69,9)</span><br></th>
                                    <th class="tg-0lax"><span
                                            style="font-weight:700;font-style:normal;text-decoration:none">Tidak
                                        </span><span style="font-weight:700">Bagus (dibawah 65)</span><br></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="tg-0lax" rowspan="2"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">1</span>
                                    </td>
                                    <td class="tg-0lax" rowspan="2"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Kesesuaian data dan dokumen pendukung penilaian mandiri dengan kondisi faktual dilapangan</span>
                                        
                                    </td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">a</span>
                                    </td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Ketersediaan
                                            data dan dokumen pendukung penilaian mandiri</span></td>
                                    <td><b-form-group invalid-feedback="Nilai is required" :state="p1aState"> <b-form-input
                                                type="number" style="width:100px" step="any" v-model="p1a" min="1" max="100"
                                                :state="p1aState" required></b-form-input></b-form-group></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Menilai
                                            ketersediaan data dan dokumen pendukung serta kualitas penyediaan dan organisasi
                                            dokumen dimaksud.</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Data
                                            dan dokumen pendukung tersedia dengan sangat lengkap serta memiliki
                                            manajemen/sistem pengarsipan yang sangat baik</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Data
                                            dan dokumen pendukung tersedia dengan lengkap serta memiliki manajemen/sistem
                                            pengarsipan yang baik</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Data
                                            dan dokumen pendukung tersedia dengan kurang lengkap serta memiliki
                                            manajemen/sistem pengarsipan yang kurang baik</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Data
                                            dan dokumen pendukung tersedia dengan tidak lengkap karena manajemen/sistem
                                            pengarsipan yang tidak baik</span></td>
                                </tr>
                                <tr>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">b</span>
                                    </td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Kesesuaian
                                            pelaksanaan</span></td>
                                    <td><b-form-group invalid-feedback="Nilai is required" :state="p1bState"> <b-form-input
                                                type="number" v-model="p1b" step="any" min="1" max="100" :state="p1bState"
                                                required></b-form-input></b-form-group></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Menilai
                                            kualitas kesesuain antara dokumen dengan pelaksanaan di lapangan</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Terdapat
                                            bukti yang sangat sahih semua kebijakan pelayanan telah dilakukan di
                                            lapangan</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Terdapat
                                            bukti yang sahih sebagian besar kebijakan pelayanan telah dilakukan di
                                            lapangan</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Terdapat
                                            bukti yang sahih sebagaian besar kebijakan pelayanan belum dilakukan di
                                            lapangan</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Terdapat
                                            bukti yang sahih semua kebijakan pelayanan belum dilakukan di lapangan</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="tg-0lax" rowspan="2"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">2</span>
                                    </td>
                                    <td class="tg-0lax" rowspan="2"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pelaksanaan
                                            PPB</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">a</span>
                                    </td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Penyederhaan
                                            peraturan dan pelaksanaan perizinan berusaha</span></td>
                                    <td class="tg-0lax"><b-form-group invalid-feedback="Nilai is required"
                                            :state="p2aState"> <b-form-input step="any" type="number" min="1" max="100"
                                                v-model="p2a" :state="p2aState" required></b-form-input></b-form-group></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Menilai
                                            kualitas aktivitas penyederhaan peraturan dan pelaksanaan perizinan
                                            berusaha</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Semua
                                            kegiatan penyederhaan peraturan pelaksanaan perizinan sudah selesai dilaksanakan
                                            dengan hasil-hasilnya</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Semua
                                            kegiatan penyederhaan peraturan pelaksanaan perizinan sudah selesai dilaksanakan
                                            namun dengan sebagian hasil-hasilnya</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Ada
                                            sebagian kegiatan penyederhaan peraturan pelaksanaan perizinan yang belum
                                            selesai dilaksanakan termasuk hasil-hasilnya</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Semua
                                            kegiatan penyederhaan peraturan pelaksanaan perizinan sedang dalam proses dan
                                            belum ada hasilnya</span></td>
                                </tr>
                                <tr>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">b</span>
                                    </td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Komitmen</span>
                                    </td>
                                    <td><b-form-group invalid-feedback="Nilai is required" :state="p2bState"> <b-form-input
                                                min="1" max="100" v-model="p2b" step="any" type="number" :state="p2bState"
                                                required></b-form-input></b-form-group></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Menilai
                                            komitmen jangka pendek, menengah dan panjang dari kementerian dan lembaga dalam
                                            PPB</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Tersedian
                                            program kerja jangka pendek menengah dan panjang, terkait Percepatan Pelaksanaan
                                            Berusaha berikut bukti pelaksanaannya</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Tersedian
                                            program kerja jangka pendek dan menengah, terkait Percepatan Pelaksanaan
                                            Berusaha, berikut sebagian bukti pelaksanaannya</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Tersedian
                                            program kerja jangka pendek terkait Percepatan Pelaksanaan Berusaha berikut
                                            sebagian bukti pelaksanaannya</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Tidak
                                            tersedia program kerja secara formal terkait Percepatan Pelaksanaan Berusaha
                                            meskipun ada sebagian bukti pelaksanaannya</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </form>
                <template #modal-footer>
                    <div class="w-100">
                        <!-- <p class="float-left">Modal Footer Content</p> -->
                        <b-button variant="primary" size="sm" class="float-right"
                            style="background-color: #030A8C; border-color: #030A8C; color:white"
                            @click="inputnilai(datakl.idpenilai, datakl.idpeserta)">
                            Simpan
                        </b-button>
                        <b-button variant="danger" size="sm" class="float-right" style="margin-right: 10px;"
                            @click="show = false">
                            Tutup
                        </b-button>
                    </div>
                </template>
            </b-modal>

            <!-- ubah catatan -->
            <b-modal v-model="show1" id="modal-edit" ref="modal" size="xl" title="Form Ubah Nilai" @show="resetModal"
                :no-close-on-backdrop="true" modal-class="test-modal" @ok="handleOk">
                <form ref="form" @submit.stop.prevent="ubahnilai">
                    <div class="alert alert-primary d-flex align-items-center" role="alert">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                            class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img"
                            aria-label="Warning:">
                            <path
                                d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                        </svg>
                        <div>
                            <span style="padding-right:20px"> </span> Masukkan Penilaian Anda dengan rentang 1-100
                        </div>
                    </div>

                    <div style="overflow-x:auto;">
                        <table class="table table-bordered">
                            <thead>
                                <tr>
                                    <th class="tg-1wig" colspan="2">Kriteria Penilaian</th>
                                    <th class="tg-1wig" colspan="2">Indikator Penilaian</th>
                                    <th>Nilai</th>
                                    <th class="tg-0lax">Tolok Ukur</th>
                                    <th class="tg-0lax"><span
                                            style="font-weight:700;font-style:normal;text-decoration:none">Sangat
                                        </span><span style="font-weight:700">Bagus (85 sd 100)</span></th>
                                    <th class="tg-0lax"><span
                                            style="font-weight:700;font-style:normal;text-decoration:none">Bagus
                                        </span><span style="font-weight:700">(70 sd 84,9)</span></th>
                                    <th class="tg-0lax"><span
                                            style="font-weight:700;font-style:normal;text-decoration:none">Kurang</span><span
                                            style="font-weight:700"> Bagus (65 sd 69,9)</span><br></th>
                                    <th class="tg-0lax"><span
                                            style="font-weight:700;font-style:normal;text-decoration:none">Tidak
                                        </span><span style="font-weight:700">Bagus (dibawah 65)</span><br></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="tg-0lax" rowspan="2"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">1</span>
                                    </td>
                                    <td class="tg-0lax" rowspan="2"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Kesesuaian data dan dokumen pendukung penilaian mandiri dengan kondisi faktual dilapangan</span>
                                        
                                    </td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">a</span>
                                    </td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Ketersediaan
                                            data dan dokumen pendukung penilaian mandiri</span></td>
                                    <td><b-form-group invalid-feedback="Nilai is required" :state="p1aState"> <b-form-input
                                                type="number" style="width:100px" step="any" v-model="datakl.p1a" min="1"
                                                max="100" :state="p1aState" required></b-form-input></b-form-group></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Menilai
                                            ketersediaan data dan dokumen pendukung serta kualitas penyediaan dan organisasi
                                            dokumen dimaksud.</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Data
                                            dan dokumen pendukung tersedia dengan sangat lengkap serta memiliki
                                            manajemen/sistem pengarsipan yang sangat baik</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Data
                                            dan dokumen pendukung tersedia dengan lengkap serta memiliki manajemen/sistem
                                            pengarsipan yang baik</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Data
                                            dan dokumen pendukung tersedia dengan kurang lengkap serta memiliki
                                            manajemen/sistem pengarsipan yang kurang baik</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Data
                                            dan dokumen pendukung tersedia dengan tidak lengkap karena manajemen/sistem
                                            pengarsipan yang tidak baik</span></td>
                                </tr>
                                <tr>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">b</span>
                                    </td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Kesesuaian
                                            pelaksanaan</span></td>
                                    <td><b-form-group invalid-feedback="Nilai is required" :state="p1bState"> <b-form-input
                                                type="number" v-model="datakl.p1b" step="any" min="1" max="100"
                                                :state="p1bState" required></b-form-input></b-form-group></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Menilai
                                            kualitas kesesuain antara dokumen dengan pelaksanaan di lapangan</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Terdapat
                                            bukti yang sangat sahih semua kebijakan pelayanan telah dilakukan di
                                            lapangan</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Terdapat
                                            bukti yang sahih sebagian besar kebijakan pelayanan telah dilakukan di
                                            lapangan</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Terdapat
                                            bukti yang sahih sebagaian besar kebijakan pelayanan belum dilakukan di
                                            lapangan</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Terdapat
                                            bukti yang sahih semua kebijakan pelayanan belum dilakukan di lapangan</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="tg-0lax" rowspan="2"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">2</span>
                                    </td>
                                    <td class="tg-0lax" rowspan="2"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Pelaksanaan
                                            PPB</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">a</span>
                                    </td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Penyederhanaan
                                            peraturan dan pelaksanaan perizinan berusaha</span></td>
                                    <td class="tg-0lax"><b-form-group invalid-feedback="Nilai is required"
                                            :state="p2aState"> <b-form-input step="any" type="number" min="1" max="100"
                                                v-model="datakl.p2a" :state="p2aState"
                                                required></b-form-input></b-form-group></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Menilai
                                            kualitas aktivitas penyederhanaan peraturan dan pelaksanaan perizinan
                                            berusaha.</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Semua
                                            kegiatan penyederhaan peraturan pelaksanaan perizinan sudah selesai dilaksanakan
                                            dengan hasil-hasilnya</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Semua
                                            kegiatan penyederhaan peraturan pelaksanaan perizinan sudah selesai dilaksanakan
                                            namun dengan sebagian hasil-hasilnya</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Ada
                                            sebagian kegiatan penyederhaan peraturan pelaksanaan perizinan yang belum
                                            selesai dilaksanakan termasuk hasil-hasilnya</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Semua
                                            kegiatan penyederhaan peraturan pelaksanaan perizinan sedang dalam proses dan
                                            belum ada hasilnya</span></td>
                                </tr>
                                <tr>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">b</span>
                                    </td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Komitmen</span>
                                    </td>
                                    <td><b-form-group invalid-feedback="Nilai is required" :state="p2bState"> <b-form-input
                                                min="1" max="100" v-model="datakl.p2b" step="any" type="number"
                                                :state="p2bState" required></b-form-input></b-form-group></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Menilai
                                            komitmen jangka pendek, menengah dan panjang dari kementerian dan lembaga dalam
                                            PPB</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Tersedian
                                            program kerja jangka pendek menengah dan panjang, terkait Percepatan Pelaksanaan
                                            Berusaha berikut bukti pelaksanaannya</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Tersedian
                                            program kerja jangka pendek dan menengah, terkait Percepatan Pelaksanaan
                                            Berusaha, berikut sebagian bukti pelaksanaannya</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Tersedian
                                            program kerja jangka pendek terkait Percepatan Pelaksanaan Berusaha berikut
                                            sebagian bukti pelaksanaannya</span></td>
                                    <td class="tg-0lax"><span
                                            style="font-weight:400;font-style:normal;text-decoration:none;color:#000">Tidak
                                            tersedia program kerja secara formal terkait Percepatan Pelaksanaan Berusaha
                                            meskipun ada sebagian bukti pelaksanaannya</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </form>
                <template #modal-footer>
                    <div class="w-100">
                        <!-- <p class="float-left">Modal Footer Content</p> -->
                        <b-button variant="primary" size="sm" class="float-right"
                            style="background-color: #030A8C; border-color: #030A8C; color:white"
                            @click="ubahnilai(datakl.id)">
                            Simpan
                        </b-button>
                        <b-button variant="danger" size="sm" class="float-right" style="margin-right: 10px;"
                            @click="show1 = false">
                            Tutup
                        </b-button>
                    </div>
                </template>
            </b-modal>

            <!-- lihat catatan -->
            <div class="modal fade catatan" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Catatan</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <div>
                                <div style="overflow-x:auto;">
                                    <table id="example2" class="table table-striped table-bordered" style="width:100%">
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>Judul Kajian/Berita</th>
                                                <th>Tipe Kajian/Berita</th>
                                                <th>File</th>
                                                <th>Aksi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="row in catatann" :key="row.id">
                                                <td></td>
                                                <td>{{ row.judul }}</td>
                                                <td>
                                                    <div v-if="row.tipe === 'Negatif'" style="color:red">{{ row.tipe }}
                                                    </div>
                                                    <div v-else style="color:blue">{{ row.tipe }}</div>
                                                </td>
                                                <td>
                                                    <div v-if="row.file != null">
                                                        <a :href="row.file" target="_blank"
                                                            class="btn btn-text-dark-50 btn-icon-success font-weight-bold btn-hover-bg-light mr-3 btn-lg"><i
                                                                class="fa fa-download" aria-hidden="true" /></a>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <button data-toggle="modal" data-target="#modal-catatan"
                                                            @click="getPerCatatan(row.id)" style="margin:5px"
                                                            class="btn btn-secondary" v-b-tooltip.hover title="Lihat"><i
                                                                class="fa fa-eye"></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- detail catatan -->
            <div class="modal fade" id="modal-catatan" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
                aria-hidden="true">
                <div class="modal-dialog modal-xl" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Lihat Catatan</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form ref="form" @submit.stop.prevent="tambahcatatan">


                                <div class="form-group row">
                                    <label class="col-4">Tipe</label>
                                    <div class="col-8">
                                        <b-form-select disabled v-model="getCatatan.tipe" :options="tipe"></b-form-select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label">Penghimpun Kajian/Berita</label>
                                    <div class="col-lg-8">
                                        <input disabled type="text" v-model="getCatatan.penghimpun" class="form-control" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label">Judul Kajian/Berita</label>
                                    <div class="col-lg-8">
                                        <input disabled type="text" v-model="getCatatan.judul" class="form-control" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label">Lembaga</label>
                                    <div class="col-lg-8">
                                        <input disabled type="text" v-model="getCatatan.lembaga" class="form-control" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label">Rentang Waktu</label>
                                    <div class="col-lg-8">
                                        <div class="row">
                                            <div class="col-xl-6">
                                                <!--begin::Input-->
                                                <div class="form-group">
                                                    <b-form-datepicker disabled v-model="getCatatan.tanggal_awal"
                                                        locale="in" class="mb-2"></b-form-datepicker>
                                                </div>
                                                <!--end::Input-->
                                            </div>
                                            <div class="col-xl-6">
                                                <!--begin::Input-->
                                                <div class="form-group">
                                                    <b-form-datepicker disabled v-model="getCatatan.tanggal_akhir"
                                                        locale="in" class="mb-2"></b-form-datepicker>
                                                </div>
                                                <!--end::Input-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label">Ringkasan Isi Kajian/Berita</label>
                                    <div class="col-lg-8">
                                        <div v-html="getCatatan.deskripsi"></div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label">Tautan Kajian/Berita</label>
                                    <div class="col-lg-8">
                                        <input disabled type="text" v-model="getCatatan.sumber" class="form-control" />
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label">Kajian/Berita</label>
                                    <div class="col-lg-8">
                                        <!-- <b-form-file v-model="getCatatan.file" type="file" ref="file"></b-form-file> -->
                                        <div v-if="getCatatan.file !== null">
                                            <a :href="getCatatan.file" target="_blank"
                                                class="btn btn-text-dark-50 btn-icon-success font-weight-bold btn-hover-bg-light mr-3 btn-lg"><i
                                                    class="fa fa-download" aria-hidden="true" /></a>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>

                        </div>
                    </div>
                </div>
            </div>

            <!-- modal tambah rekomendasi -->
            <b-modal id="modal-rekomendasi" ref="modal" size="xl" modal-class="test-modal" title="Form Rekomendasi"
                scrollable @show="resetModal" :no-close-on-backdrop="true" @ok="handleOk3">
                <form ref="form3" @submit.stop.prevent="inputrekomendasi">
                    <div class="row">
                        <div class="col-md-12">
                            <h5>A. Kesesuaian Data dan Dokumen dengan Pelaksanaan</h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 ml-4">
                            <b> 1. Kelengkapan dasar hukum terkait Perizinan Berusaha (NSPK)</b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group label="Resume Hasil Uji Petik Nomine" label-for="name">
                                    <input type="text" hidden v-model="resume_nspk" />
                                    <ckeditor :editor="editor" v-model="resume_nspk" :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="resume_nspk" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group label="Rekomendasi" label-for="name" class="mb-3">
                                    <ckeditor :editor="editor" v-model="rekomendasi_nspk" :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="rekomendasi_nspk" :editor-toolbar="customToolbar"/>   -->

                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 ml-4">
                            <b> 2. Kelengkapan data dan dokumen pendukung serta pengarsipannya </b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group label="Resume Hasil Uji Petik Nomine" label-for="name" class="mb-3">
                                    <ckeditor :editor="editor" v-model="resume_data_pendukung" :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="resume_data_pendukung" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group label="Rekomendasi" label-for="name" class="mb-3">
                                    <ckeditor :editor="editor" v-model="rekomendasi_data_pendukung" :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="rekomendasi_data_pendukung" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <hr>
                    <div class="row">
                        <div class="col-md-12">
                            <h5> B. Percepatan Pelaksanaan Berusaha </h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 ml-4">
                            <b> 1. Peningkatan iklim berusaha melalui berbagai aktivitas penyederhanaan pelayanan perizinan
                                berusaha</b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group label="Resume Hasil Uji Petik Nomine" label-for="name" class="mb-3">
                                    <ckeditor :editor="editor" v-model="resume_peningkatan_iklim_berusaha" :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="resume_peningkatan_iklim_berusaha" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group label="Rekomendasi" label-for="name" class="mb-3">
                                    <ckeditor :editor="editor" v-model="rekomendasi_peningkatan_iklim_berusaha" :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="rekomendasi_peningkatan_iklim_berusaha" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 ml-4">
                            <b> 2. Tim pelaksana PPB di Kementerian Negara/Lembaga</b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group label="Resume Hasil Uji Petik Nomine" label-for="name" class="mb-3">
                                    <ckeditor :editor="editor" v-model="resume_tim_pelaksana_ppb" :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="resume_tim_pelaksana_ppb" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group label="Rekomendasi" label-for="name" class="mb-3">
                                    <ckeditor :editor="editor" v-model="rekomendasi_tim_pelaksana_ppb" :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="rekomendasi_tim_pelaksana_ppb" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 ml-4">
                            <b> 3. Dukungan dan komitmen pimpinan pada upaya menciptakan ekosistem investasi yang
                                kondusif</b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group label="Resume Hasil Uji Petik Nomine" label-for="name" class="mb-3">
                                    <ckeditor :editor="editor" v-model="resume_dukungan_pimpinan" :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="resume_dukungan_pimpinan" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group label="Rekomendasi" label-for="name" class="mb-3">
                                    <ckeditor :editor="editor" v-model="rekomendasi_dukungan_pimpinan" :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="rekomendasi_dukungan_pimpinan" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                </form>
                <template #modal-footer>
                    <div class="w-100">
                        <b-button variant="primary" size="sm" class="float-right"
                            style="background-color: #030A8C; border-color: #030A8C; color:white"
                            @click="inputrekomendasi(datakl.idpenilai, datakl.idpeserta)">
                            Simpan
                        </b-button>
                    </div>
                </template>
            </b-modal>

            <!-- modal ubah rekomendasi -->
            <b-modal id="modal-editrekomendasi" ref="modal" size="xl" modal-class="test-modal" title="Ubah Rekomendasi"
                scrollable @show="resetModal" :no-close-on-backdrop="true" @ok="handleOk3">
                <form ref="form3" @submit.stop.prevent="inputrekomendasi">
                    <div class="row">
                        <div class="col-md-12">
                            <h5>A. Kesesuaian Data dan Dokumen dengan Pelaksanaan</h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 ml-4">
                            <b> 1. Kelengkapan dasar hukum terkait perizinan berusaha (NSPK)</b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group label="Resume Hasil Uji Petik Nomine" label-for="name">
                                    <input type="text" hidden v-model="datarekomendasi.resume_nspk" />
                                    <ckeditor :editor="editor" v-model="datarekomendasi.resume_nspk" :config="editorConfig"></ckeditor>
                                    <!-- <vue-editor v-model="datarekomendasi.resume_nspk" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group label="Rekomendasi" label-for="name" class="mb-3">
                                    <!-- <vue-editor v-model="datarekomendasi.rekomendasi_nspk" :editor-toolbar="customToolbar"/>   -->

                                    <ckeditor :editor="editor" v-model="datarekomendasi.rekomendasi_nspk" :config="editorConfig"></ckeditor>
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 ml-4">
                            <b> 2. Kelengkapan data dan dokumen pendukung serta pengarsipannya </b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group label="Resume Hasil Uji Petik Nomine" label-for="name" class="mb-3">
                                    <!-- <vue-editor v-model="datarekomendasi.resume_data_pendukung" :editor-toolbar="customToolbar"/>    -->

                                    <ckeditor :editor="editor" v-model="datarekomendasi.resume_data_pendukung" :config="editorConfig"></ckeditor>
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group label="Rekomendasi" label-for="name" class="mb-3">
                                    <!-- <vue-editor v-model="datarekomendasi.rekomendasi_data_pendukung" :editor-toolbar="customToolbar"/>    -->
                                    <ckeditor :editor="editor" v-model="datarekomendasi.rekomendasi_data_pendukung" :config="editorConfig"></ckeditor>
                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <hr>
                    <div class="row">
                        <div class="col-md-12">
                            <h5> B. Percepatan Pelaksanaan Berusaha </h5>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 ml-4">
                            <b> 1. Peningkatan iklim berusaha melalui berbagai aktivitas penyederhanaan pelayanan perizinan
                                berusaha</b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group label="Resume Hasil Uji Petik Nomine" label-for="name" class="mb-3">
                                    <!-- <vue-editor v-model="datarekomendasi.resume_peningkatan_iklim_berusaha" :editor-toolbar="customToolbar"/> -->

                                    <ckeditor :editor="editor" v-model="datarekomendasi.resume_peningkatan_iklim_berusaha" :config="editorConfig"></ckeditor>

                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group label="Rekomendasi" label-for="name" class="mb-3">
                                    <!-- <vue-editor v-model="datarekomendasi.rekomendasi_peningkatan_iklim_berusaha" :editor-toolbar="customToolbar"/> -->
                                    <ckeditor :editor="editor" v-model="datarekomendasi.rekomendasi_peningkatan_iklim_berusaha" :config="editorConfig"></ckeditor>

                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 ml-4">
                            <b> 2. Tim pelaksana PPB di Kementerian Negara/Lembaga</b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group label="Resume Hasil Uji Petik Nomine" label-for="name" class="mb-3">
                                    <ckeditor :editor="editor" v-model="datarekomendasi.resume_tim_pelaksana_ppb" :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="datarekomendasi.resume_tim_pelaksana_ppb" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group label="Rekomendasi" label-for="name" class="mb-3">
                                    <!-- <vue-editor v-model="datarekomendasi.rekomendasi_tim_pelaksana_ppb" :editor-toolbar="customToolbar"/>    -->
                                    <ckeditor :editor="editor" v-model="datarekomendasi.rekomendasi_tim_pelaksana_ppb" :config="editorConfig"></ckeditor>

                                </b-form-group>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 ml-4">
                            <b> 3. Dukungan dan komitmen pimpinan pada upaya menciptakan ekosistem investasi yang
                                kondusif</b>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group label="Resume Hasil Uji Petik Nomine" label-for="name" class="mb-3">
                                    <ckeditor :editor="editor" v-model="datarekomendasi.resume_dukungan_pimpinan" :config="editorConfig"></ckeditor>

                                    <!-- <vue-editor v-model="datarekomendasi.resume_dukungan_pimpinan" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <b-form-group label="Rekomendasi" label-for="name" class="mb-3">
                                    <ckeditor :editor="editor" v-model="datarekomendasi.rekomendasi_dukungan_pimpinan" :config="editorConfig"></ckeditor>
                                    <!-- <vue-editor v-model="datarekomendasi.rekomendasi_dukungan_pimpinan" :editor-toolbar="customToolbar"/>    -->
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                </form>
                <template #modal-footer>
                    <div class="w-100">
                        <b-button variant="primary" size="sm" class="float-right"
                            style="background-color: #030A8C; border-color: #030A8C; color:white"
                            @click="ubahrekomendasi(datarekomendasi.id)">
                            Simpan
                        </b-button>
                    </div>
                </template>
            </b-modal>

        </div>
    </div>
    <!-- </div>
  </div> -->
</template>
<style>
div.messages {
    /* background-color: lightblue; */
    /* width: 110px; */
    height: 500px;
    overflow: auto;
}

.test-modal .modal-dialog {
    max-width: 85%;

}

.ck.ck-content ul {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 40px;
}

.ck.ck-content ol {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 40px;
}
</style>

<script src="https://code.jquery.com/jquery-3.5.1.js"></script>
<script src="https://cdn.datatables.net/1.10.24/js/jquery.dataTables.min.js"></script>
<script src="https://cdn.datatables.net/buttons/1.7.0/js/dataTables.buttons.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.53/pdfmake.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.53/vfs_fonts.js"></script>
<script src="https://cdn.datatables.net/buttons/1.7.0/js/buttons.print.min.js"></script>
<script src="https://cdn.datatables.net/buttons/1.7.0/js/buttons.html5.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jszip/3.1.3/jszip.min.js"></script>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';
import Swal from "sweetalert2";
import { LOGOUT } from "@/core/services/store/auth.module";
import { VueEditor } from "vue2-editor";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

import Vue from "vue";
import VueLoading from 'vuejs-loading-plugin'


Vue.use(VueLoading, {
  dark: false, // default false
  text: 'Loading', // default 'Loading'
  loading: false, // default false
  //customLoader: myVueComponent, // replaces the spinner and text with your own
  background: 'rgb(255,255,255)', // set custom background
  classes: ['myclass'] // array, object or string
})

export default {
  components: { VueEditor },
  data() {
      return {
        url: localStorage.getItem('baseapi'),
        token: localStorage.getItem('id_token'),
        tahun: new Date().getFullYear(),
        user: JSON.parse(localStorage.getItem('user')),
        totskor:[],
        idruangan:'',
        daerah:'',
        ruanganpenilai:[],
        tipe: [
         { value: 'Negatif', text: 'Negatif' },
         { value: 'Positif', text: 'Positif' },
        ],
        noCatatan:'',
        timpenilai:[],
        p1aState: null,
        p1bState: null,
        p2aState: null,
        p2bState: null,
        p1a:'',
        p1b:'',
        p2a:'',
        p2b:'',
        datakl:[],
        show:false,
        show1:false,
        catatann:[],
        getCatatan:{
            id:'',
            tipe:'',
            penghimpun:'',
            judul:'',
            deskripsi:'',
            sumber:''
        },
        tipe: [
         { value: 'Negatif', text: 'Negatif' },
         { value: 'Positif', text: 'Positif' },
        ],
        customToolbar: [
            ["bold", "italic", "underline"],
            [{ list: "ordered" }, { list: "bullet" }],
        ],
        editor: ClassicEditor,
        // editorConfig:{
        //     toolbar: [
        //         [ 'Bold', 'Italic', 'Underline' ],
        //         ['NumberedList', 'BulletedList'],
        //         [ 'Styles'],
		// 	]
        // },
        datarekomendasi:[],
        resume_nspkState:null,
        resume_nspk:'',
        rekomendasi_nspk:'',
        rekomendasi_nspkState:null,
        resume_data_pendukung:'',
        resume_data_pendukungState:null,
        rekomendasi_data_pendukung:'',
        rekomendasi_data_pendukungState:null,
        resume_peningkatan_iklim_berusaha:'',
        resume_peningkatan_iklim_berusahaState:null,
        rekomendasi_peningkatan_iklim_berusaha:'',
        rekomendasi_peningkatan_iklim_berusahaState:null,
        resume_tim_pelaksana_ppb:'',
        resume_tim_pelaksana_ppbState:null,
        rekomendasi_tim_pelaksana_ppb:'',
        rekomendasi_tim_pelaksana_ppbState:null,
        resume_dukungan_pimpinan:'',
        resume_dukungan_pimpinanState:null,
        rekomendasi_dukungan_pimpinan:'',
        rekomendasi_dukungan_pimpinanState:null,
      }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Uji Petik Kementerian/Lembaga" }]);
    this.getdata()
  },
  methods: {
    getdata(){
      $('#example').DataTable().destroy();
        this.loaddata()
    },
    format_desimal(value){
         if (value) {
           return value.replace(".",",");
          }
    },
    loaddata(){
      this.totskor = null;
      axios.get(this.url+"/peserta_by_penilai_uji_petik?idpenilai="+this.user.id+"&tipe=KL", {
            headers: {
                'xth': this.token
            }
            })
      .then(response => {
          var nominee = response.data.data;
          this.totskor = nominee;
          this.initDatatable();
      }).catch(error=>{
          if (error.response.data === 'Token Tidak Valid/Hak akses tidak sesuai') {
            Swal.fire({
                title: error.response.data,
                text: "",
                icon: 'error',
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'OK'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store
                        .dispatch(LOGOUT)
                        .then(() => this.$router.push({ name: "login" }));
                    }
                })
                } else {
                console.log(error)
                
                }
            return error
          });
    },
    getcatatan(idptsp){
        $('#example2').DataTable().destroy();
        this.catatann = null,
        axios.get(this.url+"/catatan_ta_kl?filter=idkl,=,"+idptsp+";tahun,=,"+this.tahun, {
            headers: {
                'xth': this.token
            }
            })
            .then(response => {
                var catatannomine = response.data.data;
                this.catatann = catatannomine;
                this.initDatatable2()
            }).catch(error=>{
                return error
        });
    },
    getPerCatatan(id){
        axios.get(this.url+"/catatan_ta_kl?filter=id,=,"+id, {
            headers: {
                'xth': this.token
            }
            }).then(response => {
            this.getCatatan.id = id;
                this.getCatatan.tipe = response.data.data[0].tipe;
                this.getCatatan.penghimpun = response.data.data[0].penghimpun;
                this.getCatatan.judul = response.data.data[0].judul;
                this.getCatatan.deskripsi = response.data.data[0].deskripsi;
                this.getCatatan.sumber = response.data.data[0].sumber;
                this.getCatatan.tanggal_awal = response.data.data[0].tanggal_awal;
                this.getCatatan.tanggal_akhir = response.data.data[0].tanggal_akhir;
                this.getCatatan.lembaga = response.data.data[0].lembaga;
                this.getCatatan.idptsp = response.data.data[0].idptsp;
                this.getCatatan.file = response.data.data[0].file;
        });
    },
    initDatatable() {
        setTimeout(() => {
        var t =  $('#example').DataTable({
            "pagingType": "full_numbers",
            "language": {
                "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                "paginate": {
                    "first":      "Awal",
                    "last":       "Akhir",
                    "next":       "Selanjutnya",
                    "previous":   "Sebelumnya"
                },
            },
            "lengthMenu": [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
            ],
            // order: [[7, 'asc']],
            responsive: true,
            destroy: true,
            retrieve: true,
            autoFill: true,
            colReorder: true,

        });
        t.on( 'order.dt search.dt', function () {
        t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
            cell.innerHTML = i+1;
        } );
        // t.column(7, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
        //     cell.innerHTML = i+1;
        // } );
        } ).draw();
        }, 300)
    },
    initDatatable2() {
        setTimeout(() => {
        var t =  $('#example2').DataTable({
            "pagingType": "full_numbers",
            "language": {
                "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                "paginate": {
                    "first":      "Awal",
                    "last":       "Akhir",
                    "next":       "Selanjutnya",
                    "previous":   "Sebelumnya"
                },
            },
            "lengthMenu": [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
            ],
            // order: [[7, 'asc']],
            responsive: true,
            destroy: true,
            retrieve: true,
            autoFill: true,
            colReorder: true,

        });
        t.on( 'order.dt search.dt', function () {
        t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
            cell.innerHTML = i+1;
        } );
        // t.column(7, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
        //     cell.innerHTML = i+1;
        // } );
        } ).draw();
        }, 300)
    },
    getdatakl(penilai, peserta){
      this.datakl.idpenilai = penilai
      this.datakl.idpeserta = peserta
    },
    getdatakledit(id){
        axios.get(this.url+"/tabel_nilai_peserta_uji_petik?filter=id,=,"+id+';tahun,=,'+this.tahun, {
            headers: {
                'xth': this.token
            }
            })
        .then(response => {
            this.datakl = response.data.data[0];
        }).catch(error=>{
            console.log(error)
            return error
            // this.loaddata()
            });
    },
    persetujuanba(id) {
        axios.put(this.url+`/uji_petik_kl/${id}`,
            {
                persetujuan:this.datakl.persetujuan,
            }
            , {
                headers: {
                    'xth': this.token
                }
            })
            .then((res) => {
                this.$bvToast.toast('Berhasil', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                this.getdata()
            return res;
            })
            .catch((err) => {
            console.log(err)
            this.$bvToast.toast('Gagal', {
            title: `Failed `,
            variant: `danger`,
            solid: true
            })
            return err;
        });
        // Push the name to submitted names
        // this.submittedNames.push(this.name)
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-edit')
        })
    },
    ubahnilai(id) {
        axios.put(this.url+`/tabel_nilai_peserta_uji_petik/${id}`,
            {
                p1a:this.datakl.p1a,
                p1b:this.datakl.p1b,
                p2a:this.datakl.p2a,
                p2b:this.datakl.p2b,
            }
            , {
                headers: {
                    'xth': this.token
                }
            })
            .then((res) => {
                this.$bvToast.toast('Berhasil Diedit', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                this.getdata()
            return res;
            })
            .catch((err) => {
            this.$bvToast.toast('Gagal Diedit', {
                title: `Failed `,
                variant: `danger`,
                solid: true
            })
            return err;
        });
        // Push the name to submitted names
        // this.submittedNames.push(this.name)
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-edit')
        })
    },
    checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        this.p1aState = valid
        this.p1bState = valid
        this.p2aState = valid
        this.p2bState = valid
        return valid
      },
    resetModal() {
        this.p1a=''
        this.p1b=''
        this.p2a=''
        this.p2b=''
        this.p1aState = null
        this.p1bState = null
        this.p2aState = null
        this.p2bState = null

      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.inputnilai()
      },
    inputnilai(penilai,peserta) {
        const p1a = this.p1a;
        const p1b = this.p1b;
        const p2a = this.p2a;
        const p2b = this.p2b;
        const tahun = this.tahun;
        const idpenilai = penilai;
        const idpeserta = peserta;
        if (!this.checkFormValidity()) {
          return
        }
        // console.log(id_peserta)
        
        axios
            .post(this.url+"/tabel_nilai_peserta_uji_petik",
            {
                p1a,
                p1b,
                p2a,
                p2b,
                tahun,
                idpenilai,
                idpeserta,
            }
            , {
                headers: {
                    'xth': this.token
                }
            })
            .then((res) => {
                this.$bvToast.toast('Berhasil Disimpan', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                this.resetModal()
                this.getdata()
                
            return res;
            })
            .catch((err) => {
            console.log(err)
            this.$bvToast.toast('Gagal Disimpan', {
                title: `Failed `,
                variant: `danger`,
                solid: true
            })
            return err;
        });
        // Push the name to submitted names
        // this.submittedNames.push(this.name)
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-input')
        })
    },

    cekba(idpeserta){
        var api = ''
            api = this.url+'/tabel_nomine_kl?filter=tahun,=,'+this.tahun+';idkl,=,'+idpeserta
       axios.get(api, {
                  headers: {
                      'xth': this.token
                  }
              })
            .then(response => {
                var data = []
                data =  response.data.data;
                this.downloadba(data[0].file_ba_uji_petik)
            }).catch(error=>{
                console.log(error)
                return error
                });
        
    },

    downloadba(ba){
        if (ba == null) {
            Swal.fire({
            title: "",
            text: "File BA Belum ada",
            icon: "info",
            confirmButtonClass: "btn btn-secondary"
            });
            this.getdata()
        }else{
            window.open(ba, '_blank')
        }
    },

    checkFormValidity2() {
        const valid = this.$refs.form3.checkValidity()
        this.resume_nspkState = valid
        this.rekomendasi_nspkState=valid
        this.resume_data_pendukungState=valid
        this.rekomendasi_data_pendukungState=valid
        
        this.resume_peningkatan_iklim_berusahaState=valid
        this.rekomendasi_peningkatan_iklim_berusahaState=valid
        this.resume_tim_pelaksana_ppbState=valid
        this.rekomendasi_tim_pelaksana_ppbState=valid
        this.resume_dukungan_pimpinanState=valid
        this.rekomendasi_dukungan_pimpinanState=valid
        return valid
      },
    
     resetModalRekomendasi() {
        this.resume_nspkState=null
        this.resume_nspk=""
        this.rekomendasi_nspk=""
        this.rekomendasi_nspkState=null
        this.resume_data_pendukung=""
        this.resume_data_pendukungState=null
        this.rekomendasi_data_pendukung=null
        this.rekomendasi_data_pendukungState=null
        this.resume_peningkatan_iklim_berusaha=null
        this.resume_peningkatan_iklim_berusahaState=null
        this.rekomendasi_peningkatan_iklim_berusaha=null
        this.rekomendasi_peningkatan_iklim_berusahaState=null
        this.resume_tim_pelaksana_ppb=null
        this.resume_tim_pelaksana_ppbState=null
        this.rekomendasi_tim_pelaksana_ppb=null
        this.rekomendasi_tim_pelaksana_ppbState=null
        this.resume_dukungan_pimpinan=null
        this.resume_dukungan_pimpinanState=null
        this.rekomendasi_dukungan_pimpinan=null
        this.rekomendasi_dukungan_pimpinanState=null

      },

    handleOk3(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.inputrekomendasi()
      },
    
    // input nilai
    inputrekomendasi(penilai, peserta) {
        this.$loading(true);
        const resume_nspk = this.resume_nspk;
        const rekomendasi_nspk = this.rekomendasi_nspk;
        const resume_data_pendukung = this.resume_data_pendukung;
        const rekomendasi_data_pendukung = this.rekomendasi_data_pendukung;
       
        const resume_peningkatan_iklim_berusaha = this.resume_peningkatan_iklim_berusaha;
        const rekomendasi_peningkatan_iklim_berusaha = this.rekomendasi_peningkatan_iklim_berusaha;
        const resume_tim_pelaksana_ppb = this.resume_tim_pelaksana_ppb;
        const rekomendasi_tim_pelaksana_ppb = this.rekomendasi_tim_pelaksana_ppb;
        const resume_dukungan_pimpinan = this.resume_dukungan_pimpinan;
        const rekomendasi_dukungan_pimpinan = this.rekomendasi_dukungan_pimpinan;
        const tahun = this.tahun;
        const id_penilai = penilai;
        const id_peserta = peserta;

        // console.log(id_peserta)
        
        axios
            .post(this.url+"/rekomendasi_tim_penilai_kl",
            {
                resume_nspk,
                rekomendasi_nspk,
                resume_data_pendukung,
                rekomendasi_data_pendukung,
                
                resume_peningkatan_iklim_berusaha,
                rekomendasi_peningkatan_iklim_berusaha,
                resume_tim_pelaksana_ppb,
                rekomendasi_tim_pelaksana_ppb,
                resume_dukungan_pimpinan,
                rekomendasi_dukungan_pimpinan,
                tahun,
                id_penilai,
                id_peserta,
            }
            , {
                headers: {
                    'xth': this.token
                }
            })
            .then((res) => {
                this.$loading(false);
                this.$bvToast.toast('Berhasil Disimpan', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                // this.resetModalRekomendasi()
                // this.datarekomendasi = []
                
            return res;
            })
            .catch((err) => {
                this.$loading(false);
            console.log(err)
            this.$bvToast.toast('Gagal Disimpan', {
            title: `Failed `,
            variant: `danger`,
            solid: true
            })
            return err;
        });
        // Push the name to submitted names
        // this.submittedNames.push(this.name)
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-rekomendasi')
        })
    },

    // lihat nilai
    nilai(data){
        Swal.fire({
            title: 'Nilai',
            icon: 'info',
            html:
                data.nama_peserta + '<br>' +
              '<strong style="font-size:30px">'+ this.format_desimal(data.nilai_uji_petik) + '</strong>',
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            showConfirmButton: false,
        })
    },

    ubahrekomendasi(id){
        this.$loading(true);
        axios.put(this.url+`/rekomendasi_tim_penilai_kl/${id}`,
            {
                resume_nspk : this.datarekomendasi.resume_nspk,
                rekomendasi_nspk : this.datarekomendasi.rekomendasi_nspk,
                resume_data_pendukung : this.datarekomendasi.resume_data_pendukung,
                rekomendasi_data_pendukung : this.datarekomendasi.rekomendasi_data_pendukung,
                
                resume_peningkatan_iklim_berusaha : this.datarekomendasi.resume_peningkatan_iklim_berusaha,
                rekomendasi_peningkatan_iklim_berusaha : this.datarekomendasi.rekomendasi_peningkatan_iklim_berusaha,
                resume_tim_pelaksana_ppb : this.datarekomendasi.resume_tim_pelaksana_ppb,
                rekomendasi_tim_pelaksana_ppb : this.datarekomendasi.rekomendasi_tim_pelaksana_ppb,
                resume_dukungan_pimpinan : this.datarekomendasi.resume_dukungan_pimpinan,
                rekomendasi_dukungan_pimpinan : this.datarekomendasi.rekomendasi_dukungan_pimpinan,
                tahun : this.datarekomendasi.tahun,
                id_penilai : this.datakl.idpenilai,
                id_peserta : this.datakl.idpeserta,
            }
            , {
            headers: {
                'xth': this.token
            }
            })
            .then((res) => {
                this.$loading(false);
                this.$bvToast.toast('Berhasil Diedit', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                this.getdata()
            return res;
            })
            .catch((err) => {
                this.$loading(false);
            console.log(err)
            this.$bvToast.toast('Gagal Diedit', {
            title: `Failed `,
            variant: `danger`,
            solid: true
            })
            return err;
        });
        // Push the name to submitted names
        // this.submittedNames.push(this.name)
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-editrekomendasi')
        })
    },

    // cek rekomendasi
    cekrekomendasi(idpenilai,idptsp){
        axios.get(this.url+"/rekomendasi_tim_penilai_kl?filter=id_peserta,=,"+idptsp+";tahun,=,"+this.tahun, {
            headers: {
                'xth': this.token
            }
            })
        .then(response => {
            var data = response.data.data;
            // this.datarekomendasi = response.data.data[0];
            if (data.length > 0) {
                this.getdatakl(idpenilai,idptsp)
                // this.datarekomendasi.resume_nspk = data[0].resume_nspk
                this.datarekomendasi = response.data.data[0];
                this.$bvModal.show('modal-editrekomendasi')
            } else {
                console.log(idpenilai, idptsp)
                console.log(this.resume_nspk)
                this.getdatakl(idpenilai,idptsp)
                this.$bvModal.show('modal-rekomendasi')
            }
        }).catch(error=>{
            return error
            });
    },

    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  },
  created(){
    //   this.getuser(this.user)
    // this.getlocaldata()
  }
};
</script>
